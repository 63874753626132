.container.contact__container {
    width: 58%;
    display: grid;
    grid-template-columns: 30% 58%;
    gap: 12%;
}

.contact__options {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.contact__option {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
}

.contact__option:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
}

.contact__option-icon {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.contact__option a{
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
}

/*   TEST    */

.toast{
    position: fixed;
    top: 25px;
    right: 35px;
    border-radius: 12px;
    background: white;
    padding: 20px 35px 20px 25px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    border-left: 6px solid blue;
    overflow: hidden;
    transform: translateX(calc(100% + 40px));
    transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.35);
}
.toast.active{
    transform: translateX(0%);
}

.toast .toast-content{
    display: flex;
    align-items: center;
}
.toast-content .check{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 35px;
    background-color: var(--color-primary);
    color: #fff;
    font-size: 20px;
    border-radius: 50%;
}
.toast-content .message {
    display: flex;
    flex-direction: column;
    margin: 0 20px;
}

.message .text{
    font-size: 20px;
    font-weight: 400;
    color: var(--color-white);
}
.message .text .text-1{
    font-weight: 600;
    color: var(--color-white);
}
.toast .close{
    position: absolute;
    top: 10px;
    right: 15px;
    padding: 5px;
    cursor: pointer;
    opacity: 0.7;
}
.toast .close:hover{
    opacity: 1;
} 
.toast .progress{
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3px;
    width: 100%;
    background: #ddd;
}
.toast .progress:before{
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background-color: var(--color-primary);
}
.progress.active:before{
    animation: progress 5s linear forwards;
}
@keyframes progress {
    100% {right: 100%;}
}

/* ============ FORM =========== */

form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
} 

input, textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-primary-variant);
    resize: none;
    color: var(--color-white);
}

.button1 {
    display: inline-block;
    margin: 0 auto;
}

/* =============== MEDIA QUARIES (MEDIUM DEVICES) =================*/
@media screen and (max-width: 1024px) {
    .container.contact__container {
        grid-template-columns: 1fr;
        gap: 2rem;
    }
}

/* =============== MEDIA QUARIES (SMALL DEVICES) =================*/
@media screen and (max-width: 600px) {
    .container.contact__container {
        grid-template-columns: 1fr;
        width: var(--container-width-sm);
    }
}





