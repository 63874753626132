.portfolio__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

.portfolio__item {
    background: var(--color-bg-variant);
    padding: 0.3rem; 
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.portfolio__item:hover {
    border-color: var(--color-primary-variant);
    /* background: transparent; */
    transform: perspective(500px) translateZ(30px);
}

.portfolio__item-image {
    border-radius: 1.5rem;
    overflow: hidden;
    height: 300px;
    width: auto;
    object-fit: fill;
}

.portfolio__item h3 {
    margin: 1rem 0 1rem;
    text-align: center;
}

.portfolio__item-cta {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 1rem;
}

/* =============== MEDIA QUARIES (MEDIUM DEVICES) =================*/
@media screen and (max-width: 1024px) {
    .portfolio__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}

/* =============== MEDIA QUARIES (SMALL DEVICES) =================*/
@media screen and (max-width: 600px) {
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}