header {
    height:  100vh;
    padding-top:  5rem;
    overflow: hidden;
}

.header__container {
    text-align: center;
    /* height: 100%; */
    position: relative;
}

/* =============  CTA  ==============*/
.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* ============== SOCIALS ===============*/
.header__socials {
    display: flex;
    font-size: 1.5rem;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.header__socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/*============ ME ==============*/
.me{
    display: block;
    margin: auto;
    width: 50vh;
    height: 58vh;
    border-radius: 25px;
    background: #6d9ced;
    /* position: absolute; */
    /* left: calc(50% - 11rem); */
    margin-top: 3rem;
    /* border-radius: 12rem 12rem 0 0; */
    /* overflow: hidden; */
    /* border: 2px solid black; */
    /* padding: 5rem 0 0 0; */
}
#img {
    position: relative;
    /* top: 50px; */
    width: 50vh; 
    /* width: 100%; */
    opacity: 1;
}

/* ============== SCROLL DOWN =============*/
.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

/* =============== MEDIA QUARIES (MEDIUM DEVICES) =================*/
@media screen and (max-width: 1024px) {
    header {
        height: 88vh;
        padding-top: 2rem;
    }
    .me {
        margin-top: 2rem;
    }
}

/* =============== MEDIA QUARIES (SMALL DEVICES) =================*/
@media screen and (max-width: 600px) {
    header {
        height: 100vh;
    }

    .header__socials,
    .scroll__down {
        display: none;
    }
    .me{
        position: relative;
        width: 80%;
        margin: 5vh 5vh;
        height: 90vw;
        overflow: hidden;
    }
    #img{
        position: absolute;
        width: 100%;
        bottom: 0;
    }
}